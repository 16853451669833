import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const data = [
    {
        name: "Basic information",
        cards: [
            { type: "education.28", data: "5 cards" },
            { type: "education.29", data: "5 cards" },
            { type: "education.30", data: "5 cards" },
            { type: "education.31", data: "6 cards" },
            { type: "education.32", data: "6 cards" },
            { type: "education.33", data: "6 cards" },
            { type: "education.34", data: "6 cards" },
            { type: "education.35", data: "6 cards" },
            { type: "education.36", data: "6 cards" },
        ]
    },
    {
        name: "Commodities",
        cards: [
            { type: "education.37", data: "5 cards" },
            { type: "education.38", data: "5 cards" },
            { type: "education.39", data: "5 cards" },
            { type: "education.40", data: "6 cards" },
            { type: "education.41", data: "6 cards" },
            { type: "education.42", data: "6 cards" },
            { type: "education.43", data: "6 cards" },
            { type: "education.44", data: "6 cards" },
            { type: "education.45", data: "6 cards" },
        ]
    },
    {
        name: "Stocks",
        cards: [
            { type: "education.46", data: "5 cards" },
            { type: "education.47", data: "5 cards" },
            { type: "education.48", data: "5 cards" },
            { type: "education.49", data: "6 cards" },
            { type: "education.50", data: "6 cards" },
            { type: "education.51", data: "6 cards" },
            { type: "education.52", data: "6 cards" },
            { type: "education.53", data: "6 cards" },
            { type: "education.54", data: "6 cards" },
        ]
    },
    {
        name: "Crypto",
        cards: [
            { type: "education.55", data: "5 cards" },
            { type: "education.56", data: "5 cards" },
            { type: "education.57", data: "5 cards" },
            { type: "education.58", data: "6 cards" },
            { type: "education.59", data: "6 cards" },
            { type: "education.60", data: "6 cards" },
            { type: "education.61", data: "6 cards" },
            { type: "education.62", data: "6 cards" },
            { type: "education.63", data: "6 cards" },
        ]
    },
]

function Switcher () {

    const { t } = useTranslation();

    const [selectedCategory, setSelectedCategory] = useState(0);

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
          document.body.classList.add('overflow-hidden');
        } else {
          document.body.classList.remove('overflow-hidden');
        }
      }, [isOpen]);

    return(
        <>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-[999]">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center relative xl:w-[500px] flex flex-col items-center">
                        <button 
                            onClick={closeModal} 
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl">
                            &times;
                        </button>
                        <h1 className="text-2xl font-bold mb-4">{t('education.23')}</h1>
                        <p className="mb-4">{t('education.24')}</p>
                        <div className='flex gap-[50px]'>
                            <Link to="https://user.bridgeinvestmarket.com/"><div className="border border-[#F75524] rounded-[10px] py-[2px] px-[20px] mt-[-2px]">
                                <p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('education.25')}</p>
                            </div></Link>
                            <Link to="https://user.bridgeinvestmarket.com/"><p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('education.26')}</p></Link>
                        </div>
                    </div>
                </div>
            )}
            <div className="bg-white rounded-t-[40px] xl:rounded-t-[100px] mt-[-100px] relative z-50 overflow-hidden" id="switcher">
                <div className="flex flex-col pt-[50px] xl:pt-[100px] max-w-[1280px] mx-auto">
                    <div className="flex flex-wrap gap-[50px] mx-[20px] 2xl:mx-">
                        {data.map((category, index) => (
                        <button 
                            key={index} 
                            className={`mx-auto ${selectedCategory === index ? 'text-[#F75524]' : 'text-[#000]'}`}
                            onClick={openModal}
                        >
                            {t(category.name)} ({category.cards.length})
                        </button>
                        ))}
                    </div>
                    <div className="flex flex-wrap mt-[44px] gap-[50px] mx-[20px] 2xl:mx-0">
                        {data[selectedCategory].cards.map((card, cardIndex) => (
                        <div key={cardIndex} className="h-[220px] w-[384px] border border-[#6E7882] rounded-[40px] flex flex-col flex-grow-[1]">
                            <h3 className="inter text-[22px] font-[600] mt-[30px] mx-[30px]">{t(card.type)}</h3>
                            <div className="flex absolute mx-[30px] mt-[150px] items-center">
                                <Link to="https://user.bridgeinvestmarket.com/"><p className="text-[18px] inter text-[#16C768] cursor-pointer">{t('education.27')} ⇒</p></Link>
                                <p className="inter text-[#D9D9D9] text-[14px] ml-[150px]">{t(card.data)}</p>
                            </div>

                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Switcher;