import React, { useEffect } from 'react';

import main from '../../img/Accounts/main.png'

import Fade from 'react-reveal/Fade'

import y from '../../img/Accounts/g_yes.png'
import n from '../../img/Accounts/g_no.png'

import { useTranslation, Trans } from "react-i18next";

const features = [
    "accounts.22",
    "accounts.23",
    "accounts.24",
    "accounts.25",
    "accounts.26",
    "accounts.27",
    "accounts.28",
    "accounts.29",
    "accounts.30",
    "accounts.31",
    "accounts.32",
    "accounts.33",
    "accounts.34",
    "accounts.35",
    "accounts.36",
    "accounts.37",
    "accounts.38",
    "accounts.39",
    "accounts.40"
  ];
  
  const plans = ["Basic", "Gold", "Platinum", "Diamond", "VIP"];
  
  const planFeatures = [
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [true, true, true, true, true],
    [false, true, true, true, true],
    [false, true, true, true, true],
    [false, false, false, false, true],
    [false, false, false, false, true],
    [false, false, false, true, false],
  ];

function Main () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const { t } = useTranslation();
    return(
        <div>
            <div className="relative overflow-hidden">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative">
                    <div className="flex justify-center xl:justify-normal">
                        <div className="absolute">
                            <Fade delay={500}><p className="helvetica mt-[98px] xl:mt-[203px] text-white text-[40px] xl:text-[74px] leading-[1.2] text-center xl:text-start"><span className='text-[#F75524]'>{t('accounts.1')}</span><Trans>{t('accounts.2')}</Trans></p></Fade>
                        </div>
                    </div>
                </div>
                <img src={main} alt="" className="w-full object-cover h-[600px] xl:h-[750px]"/>
                <div className='absolute h-[100px] bg-white w-full bottom-0 rounded-t-[40px] xl:rounded-t-[100px]'></div>
            </div>
            <div className="overflow-x-auto max-w-[1280px] mx-[20px] xl:mx-auto pb-[50px]">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                    <tr>
                        <th className="px-4 py-2 border-b inter">{t('accounts.41')}</th>
                        {plans.map((plan, index) => (
                        <th key={index} className="px-4 py-2 border-b text-[#F75524] inter">{plan}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {features.map((feature, featureIndex) => (
                        <tr key={featureIndex}>
                        <td className="px-4 py-2 border-b inter">{t(feature)}</td>
                        {plans.map((_, planIndex) => (
                            <td key={planIndex} className="px-4 py-2 border-b text-center">
                                <img 
                                    src={planFeatures[featureIndex][planIndex] ? y : n} 
                                    alt={planFeatures[featureIndex][planIndex] ? 'Checkmark' : 'Cross'} 
                                    className="h-5 w-5 mx-auto"
                                />
                            </td>
                        ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
}

export default Main;