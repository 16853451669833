import React, { useEffect, useRef } from 'react';
import LazyLoad from "react-lazyload";
import Main from "../components/Education/Main";
import Switcher from "../components/Education/Switcher";
import SeeVideo from "../components/Education/SeeVideo";
import Help from "../components/Education/Help";

function Education() {
    const switcherRef = useRef(null);

    const scrollToSwitcher = () => {
        const switcherElement = document.getElementById('switcher');
        if (switcherElement) {
          switcherElement.scrollIntoView({ behavior: 'smooth' });
        }
      };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Main scrollToSwitcher={scrollToSwitcher} />
            <Switcher ref={switcherRef} />
            <LazyLoad><SeeVideo/></LazyLoad>
            <LazyLoad><Help/></LazyLoad>
        </div>
    );
}

export default Education;