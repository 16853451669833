import React, { useEffect, useState } from 'react';

import main from '../../img/Faq/main.png'

import Fade from 'react-reveal/Fade'

import button from '../../img/Faq/button.png'

import pdf from '../../img/Faq/pdf.png'

import { useTranslation, Trans } from "react-i18next";

const data = [
    {
        title: 'faq.3',
        faq: [
            {
                title: 'faq.4',
                answer: 'faq.5',             
            },
            {
                title: 'faq.6',
                answer: 'faq.7',             
            },
            {
                title: 'faq.8',
                answer: 'faq.9',             
            },
            {
                title: 'faq.10',
                answer: 'faq.11',             
            },
            {
                title: 'faq.12',
                answer: 'faq.13',             
            },
        ]
    },
    {
        title: 'faq.14',
        faq: [
            {
                title: 'faq.15',
                answer: 'faq.16',             
            },
            {
                title: 'faq.17',
                answer: 'faq.18',             
            },
            {
                title: 'faq.19',
                answer: 'faq.20',             
            },
            {
                title: 'faq.21',
                answer: 'faq.22',             
            },
            {
                title: 'faq.23',
                answer: 'faq.24',             
            },
            {
                title: "faq.25",
                answer: "faq.26"
            },
            {
                title: "faq.27",
                answer: "faq.28"
            },
            {
                title: "faq.29",
                answer: "faq.30"
            },
            {
                title: "faq.31",
                answer: "faq.32"
            },
            {
                title: "faq.33",
                answer: "faq.34"
            },
            {
                title: "faq.35",
                answer: "faq.36"
            },
            {
                title: "faq.37",
                answer: "faq.38"
            },
            {
                title: "faq.39",
                answer: "faq.40"
            },
            {
                title: "faq.41",
                answer: "faq.42"
            },
            {
                title: "faq.43",
                answer: "faq.44"
            },
            {
                title: "faq.45",
                answer: "faq.46"
            },
            {
                title: "faq.47",
                answer: "faq.48"
            },
            {
                title: "faq.49",
                answer: "faq.50"
            },
            {
                title: "faq.51",
                answer: "faq.52"
            },
            {
                title: "faq.53",
                answer: "faq.54"
            },
            {
                title: "faq.55",
                answer: "faq.56"
            },
            {
                title: "faq.57",
                answer: "faq.58"
            },
            {
                title: "faq.59",
                answer: "faq.60"
            },
        ]
    },
    {
        title: 'faq.61',
        faq: [
            {
                title: "faq.62",
                answer: "faq.63"
            },
            {
                title: "faq.64",
                answer: "faq.65"
            },
            {
                title: "faq.66",
                answer: "faq.67"
            },
            {
                title: "faq.68",
                answer: "faq.69"
            },
            {
                title: "faq.70",
                answer: "faq.71"
            },
            {
                title: "faq.72",
                answer: "faq.73"
            },
            {
                title: "faq.74",
                answer: "faq.75"
            },
            {
                title: "faq.76",
                answer: "faq.77"
            },
            {
                title: "faq.78",
                answer: "faq.79"
            },
            {
                title: "faq.80",
                answer: "faq.81"
            },
            {
                title: "faq.82",
                answer: "faq.83"
            },
            {
                title: "faq.84",
                answer: "faq.85"
            },
            {
                title: "faq.86",
                answer: "faq.87"
            },
            {
                title: "faq.88",
                answer: "faq.89"
            }
        ]
    },
    {
        title: 'addedsep.1',
        type: 'pdf',
    }
]

const data2 = [
    {
      pdf: '/docs/Client_Money_Rules.pdf',
      text: 'Client Money Rules'  
    },
    {
        pdf: '/docs/Execution_Risks.pdf',
        text: 'Execution Risks'  
      },
      {
        pdf: '/docs/Key_Information_document.pdf',
        text: 'Key Information document'  
      },
      {
        pdf: '/docs/Managing_Conflicts_of_Interest_Policy.pdf',
        text: 'Managing Conflicts of Interest Policy'  
      },
      {
        pdf: '/docs/Order_Execution_Policy.pdf',
        text: 'Order Execution Policy'  
      },
      {
        pdf: '/docs/Rate_Card.pdf',
        text: 'Rate Card'  
      },
      {
        pdf: '/docs/Risk_Disclosure.pdf',
        text: 'Risk Disclosure'  
      },
      {
        pdf: '/docs/Terms_of_Business.pdf',
        text: 'Terms of Business'  
      },
      {
        pdf: '/docs/Website_Terms_and_Conditions.pdf',
        text: 'Website Terms and Conditions'  
      },
]
function Main () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const { t } = useTranslation();

      const [active, setActive] = useState(0);

      const [openIndex, setOpenIndex] = useState(0);
  
      const toggleText = (index) => {
          setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
      };

      console.log(data[active].type)

    return(
        <div className='pb-[100px]'>
            <div className="relative overflow-hidden">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative">
                    <div className="flex justify-center xl:justify-normal">
                        <div className="absolute">
                            <Fade delay={500}><p className="helvetica mt-[98px] xl:mt-[203px] text-white text-[40px] xl:text-[74px] leading-[1.2] text-center xl:text-start"><span className='text-[#F75524]'>{t('faq.1')}</span><Trans>{t('faq.2')}</Trans></p></Fade>
                        </div>
                    </div>
                </div>
                <img src={main} alt="" className="w-full object-cover h-[600px] xl:h-[750px]"/>
                <div className='absolute h-[100px] bg-white w-full bottom-0 rounded-t-[40px] xl:rounded-t-[100px]'></div>
            </div>
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto'>
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="flex flex-col gap-y-[20px]">
                        {data.map((ttt, index) => (
                            <div key={index} className="cursor-pointer" onClick={() => setActive(index)}>
                                <p className={`bebas text-[16px] xl:text-[18px] leading-[1.2] ${active === index ? 'text-[#F75524]' : 'text-[#000]'}`}>
                                    {active === index ? <>― </> : <></>}
                                    {t(ttt.title)}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="xl:w-[894px] mt-[20px] xl:mt-0">
                        {data[active].faq && data[active].faq.length > 0 && (
                            <div className="max-w-[894px]">
                                {data[active].faq.map((faqItem, faqIndex) => (
                                    <div key={faqIndex}>
                                        <div className={`flex mt-[10px] transition-all duration-300 border ${openIndex === faqIndex ? 'h-auto rounded-[20px]' : 'h-[52px] items-center rounded-[40px]'}`}>
                                            <div className={`cursor-pointer w-[42px] h-[42px] bg-[#F75524] rounded-full flex items-center justify-center ml-[5px] ${openIndex === faqIndex ? 'mt-[5px]' : 'mt-0'}`} onClick={() => toggleText(faqIndex)}>
                                                <img src={button} alt="" className={`${openIndex === faqIndex ? 'transition-transform rotate-45' : 'transition-transform'}`} />
                                            </div>
                                            <div>
                                                <p className={`max-w-[200px] xl:max-w-max ml-[26px] inter text-[14px] xl:text-[16px] text-explore ${openIndex === faqIndex ? 'mt-[15px]' : 'mt-0'}`}>{t(faqItem.title)}</p>
                                                {openIndex === faqIndex && (
                                                    <p className='ml-[26px] inter text-[#000000B2] font-light text-[14px] xl:text-[16px] mt-[10px] pb-[10px] max-w-[250px] xl:max-w-[600px]'><Trans>{t(faqItem.answer)}</Trans></p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {data[active].type === 'pdf' && (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-[50px]">
                            {data2.map((dat, index) => (
                                <a href={dat.pdf} key={index}>
                                <div className="flex items-center gap-[25px]">
                                    <img src={pdf} alt="" />
                                    <p className="open-sans font-[300] text-[14px] xl:text-[16px]">{t(dat.text)}</p>
                                </div>
                                </a>
                            ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Main;