import React, { useState, useEffect } from 'react';

import placeholderImageUrl from '../img/Home/video1.png'

import axios from 'axios';

import main from '../img/1.png'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function News () {

    const [feedItems, setFeedItems] = useState([]);

    const { t } = useTranslation();

    setTimeout(() => {
        console.log(document.getElementsByClassName('tawk-custom-color'));

    }, 1000)

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await axios.get('https://corsproxy.io/?' + encodeURIComponent('https://www.investing.com/rss/news.rss'));
            const parser = new DOMParser();
            const xml = parser.parseFromString(response.data, 'application/xml');
            const items = xml.querySelectorAll('item');
            const parsedItems = Array.from(items).map(item => {
                const enclosureElement = item.querySelector('enclosure');
                return {
                title: item.querySelector('title').textContent,
                link: item.querySelector('link').textContent,
                pubDate: item.querySelector('pubDate').textContent,
                author: item.querySelector('author').textContent,
                imageUrl: enclosureElement ? enclosureElement.getAttribute('url') : ''
                };
            });
            setFeedItems(parsedItems);
        } catch (error) {
            console.error('Error fetching data:', error); 
        }
        };

        fetchData();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <div className="relative overflow-hidden">
                <div className="max-w-[1280px] mx-auto relative">
                    <div className="flex justify-center xl:justify-normal">
                        <div className="absolute">
                            <Fade delay={500}><p className="helvetica mt-[98px] xl:mt-[200px] text-white text-[40px] xl:text-[74px] leading-[1.2] text-center xl:text-start">{t('news.1')}</p></Fade>
                            <Fade delay={700}><p className="inter text-[14px] xl:text-[18px] text-white mt-[20px] text-center xl:text-start max-w-[509px] mx-auto xl:mx-0">{t('news.2')}</p></Fade>
                        </div>
                    </div>
                </div>
                <img src={main} alt="" className="w-full object-cover h-[800px]"/>
                <div className='absolute h-[100px] bg-white w-full bottom-0 rounded-t-[40px] xl:rounded-t-[100px]'>

                </div>
            </div>
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto pb-[20px]'>
                <div className='grid grid-cols-1 xl:grid-cols-2 gap-y-[50px] justify-items-center'>
                {feedItems.map((item, index) => (
                    <div key={index} className='xl:w-[586px] widnews'>
                        <div className='h-[270px] overflow-hidden relative'>
                            <img src={item.imageUrl || placeholderImageUrl} alt="" className='object-cover w-full h-full blur-[3px]'/>
                            <div className='absolute w-[170px] top-[50%] left-[50%] h-[50px] -translate-x-1/2 bg-[#F75524CC] rounded-[10px] cursor-pointer'>
                                <p className='text-white text-[20px] helvetica text-center mt-[10px]'>{item.author}</p>
                            </div>
                        </div>
                        <div className='h-[185px] bg-[#111113] mt-[-3px] flex flex-col justify-between'>
                            <p className='text-white mx-[40px] pt-[30px] helvetica text-[16px] xl:text-[20px]'>{item.title}</p>
                            <div className='mx-[40px] flex justify-between pb-[20px]'>
                                <p className='text-[#F75524] helvetica text-[14px] xl:text-[16px]'>{item.pubDate}</p>
                                <a href={item.link}><p className='text-[#F75524] helvetica text-[14px] xl:text-[16px] underline'>{t('news.3')}</p></a>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
    );
}

export default News;