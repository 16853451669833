import React from "react";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import a from '../../img/Home/acompany.png'
import a2 from '../../img/Home/acompany2.png'
import a2phone from '../../img/Home/acompany_phone.png'

function AdvantagesCompany () {

    const { t } = useTranslation();

    return(
        <div className="bg-white relative mt-[90px] xl:mt-[180px] xl:pb-[110px]">
            <div className="max-w-[1280px] mx-auto relative">
             <div className="flex flex-col xl:flex-row">
                <div className="xl:w-[1280px] mx-[20px] xl:mx-0">
                    <p className="helvetica xl:text-[42px] text-[30px] max-w-[519px] text-center xl:text-left">{t('main.8')}</p>
                    <div className="flex items-start mt-[20px] xl:mt-[44px]">
                        <p className="text-[#F75524] inter text-[14px] xl:text-[18px]">✔</p>
                        <p className="inter text-[14px] xl:text-[18px] ml-[14px] max-w-[273px]">{t('main.9')}</p>
                    </div>
                    <div className="bg-[#6E7882] h-[1px] xl:w-[60%] mt-[15px]"></div>
                    <div className="flex items-start mt-[20px]">
                        <p className="text-[#F75524] inter text-[14px] xl:text-[18px]">✔</p>
                        <p className="inter text-[14px] xl:text-[18px] ml-[14px] max-w-[273px]">{t('main.10')}</p>
                    </div>
                    <div className="bg-[#6E7882] h-[1px] xl:w-[50%] mt-[15px]"></div>
                    <div className="flex items-start mt-[20px]">
                        <p className="text-[#F75524] inter text-[14px] xl:text-[18px]">✔</p>
                        <p className="inter text-[14px] xl:text-[18px] ml-[14px] max-w-[273px]">{t('main.11')}</p>
                    </div>
                    <div className="bg-[#6E7882] h-[1px] xl:w-[50%] mt-[15px]"></div>
                    <div className="flex items-start mt-[20px]">
                        <p className="text-[#F75524] inter text-[14px] xl:text-[18px]">✔</p>
                        <p className="inter text-[14px] xl:text-[18px] ml-[14px] max-w-[273px]">{t('main.12')}</p>
                    </div>
                    <div className="bg-[#6E7882] h-[1px] xl:w-[50%] mt-[15px]"></div>
                </div> 
                <img src={a} alt="" className="xl:absolute xl:top-[0px] right-0 mt-[20px] xl:mt-0"/>
             </div>
             <div className="w-full h-[733px] xl:h-[492px] rounded-t-[40px] xl:rounded-[100px] mt-[-50px] xl:mt-[80px] relative flex justify-center xl:justify-start">
                <img src={a2} alt="" className="w-full object-cover h-full rounded-t-[40px] xl:rounded-[100px] hidden md:block"/>
                <img src={a2phone} alt="" className="w-full object-cover h-full rounded-t-[40px] xl:rounded-[100px] md:hidden block"/>
                <div className="absolute z-50 top-[50px] xl:top-[100px] xl:right-[120px] mx-[20px] flex flex-col items-center">
                    <p className="max-w-[327px] xl:max-w-[482px] helvetica text-[30px] xl:text-[42px] text-white text-center xl:text-left">{t('main.13')}</p>
                    <p className="max-w-[467px] inter text-[14px] xl:text-[18px] text-white mt-[15px] text-center xl:text-left">{t('main.14')}</p>
                    <Link to="https://user.bridgeinvestmarket.com/"><div className="w-[282px] xl:w-[282px] h-[60px] xl:h-[60px] rounded-[16px] flex justify-center items-center bg-[#16C768] cursor-pointer mt-[40px]">
                        <p className="text-white text-[14px] xl:text-[18px] inter">{t('main.15')}</p>
                    </div></Link>
                </div>
             </div>
            </div>
        </div>
    );
}

export default AdvantagesCompany;