import React, { useState, useEffect } from 'react';

import axios from 'axios';

import needhelp from '../../img/Education/needhelp.png'
import needhelpmobile from '../../img/Education/needhelp_mobile.png'

import { useTranslation } from "react-i18next";

function Help () {

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const openModal = () => {
        setIsOpen(true);
        setIsSubmitted(false);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isOpen]);

    const validate = () => {
        const newErrors = {};
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!name) {
            newErrors.name = 'Name is required';
        }
        if (!phone) {
            newErrors.phone = 'Phone is required';
        } else if (!/^\d+$/.test(phone)) {
            newErrors.phone = 'Phone is invalid';
        }
        return newErrors;
    };

    const handleSubmit = async () => {
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await axios.post('https://civbt.xyz/api/bot/qMmpuiTAXT', {
                    email,
                    name,
                    phone
                });
                console.log('Form submitted successfully:', response.data);
                setIsSubmitted(true);
                setEmail('');
                setName('');
                setPhone('');
            } catch (error) {
                console.error('Error submitting the form:', error);
            }
        }
    };


    return(
        <>
             {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-[999]">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center relative xl:w-[500px] flex flex-col items-center">
                        <button 
                            onClick={closeModal} 
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl">
                            &times;
                        </button>
                        {!isSubmitted ? (
                            <>
                                <h1 className="text-2xl font-bold mb-4">{t('education.5')}</h1>
                                <p className="mb-4">{t('education.6')}</p>
                                <div className="w-full">
                                    <input 
                                        type="text" 
                                        placeholder="E-Mail" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={`px-[20px] border rounded-[16px] border-[#6E7882] h-[60px] w-full helvetica ${errors.email ? 'border-red-500' : ''}`}
                                    />
                                    {errors.email && <p className="text-red-500 text-left">{errors.email}</p>}
                                    <input 
                                        type="text" 
                                        placeholder="Имя" 
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className={`px-[20px] border rounded-[16px] border-[#6E7882] h-[60px] w-full helvetica mt-[10px] ${errors.name ? 'border-red-500' : ''}`}
                                    />
                                    {errors.name && <p className="text-red-500 text-left">{errors.name}</p>}
                                    <input 
                                        type="text" 
                                        placeholder="Телефон" 
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        className={`px-[20px] border rounded-[16px] border-[#6E7882] h-[60px] w-full helvetica mt-[10px] ${errors.phone ? 'border-red-500' : ''}`}
                                    />
                                    {errors.phone && <p className="text-red-500 text-left">{errors.phone}</p>}
                                    <div 
                                        className="w-full h-[60px] rounded-[16px] bg-[#F75524] mt-[10px] cursor-pointer flex items-center justify-center"
                                        onClick={handleSubmit}
                                    >
                                        <p className="helvetica text-white">{t('education.7')}</p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="text-center">
                                <h1 className="text-2xl font-bold mb-4">{t('education.8')}</h1>
                                <p className="mb-4">{t('education.9')}</p>
                                <div 
                                    className="w-full h-[60px] rounded-[16px] bg-[#F75524] mt-[10px] cursor-pointer flex items-center justify-center"
                                    onClick={closeModal}
                                >
                                    <p className="helvetica text-white">{t('education.10')}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="bg-white mt-[80px] xl:mt-[160px] relative z-50 overflow-hidden xl:pb-[100px]">
                <div className="max-w-[1280px] mx-auto">
                    <div className="h-[517px] xl:h-[298px] w-full rounded-t-[40px] xl:rounded-[100px] bg-slate-600">
                        <img src={needhelp} alt="" className="object-cover w-full h-full rounded-t-[40px] xl:rounded-[100px] hidden xl:block"/>
                        <img src={needhelpmobile} alt="" className="object-cover w-full h-full rounded-t-[40px] xl:rounded-[100px] xl:hidden block"/>
                        <div className="absolute z-50 top-[50px] xl:top-[100px] inset-0 xl:left-[400px] mx-[20px] flex flex-col items-center">
                            <p className="max-w-[327px] xl:max-w-[482px] helvetica text-[30px] xl:text-[42px] text-white text-center xl:text-left">{t('education.11')}</p>
                            <div className='w-[200px] h-[40px] rounded-[20px] bg-[#F75524] mt-[20px] cursor-pointer flex items-center justify-center' onClick={openModal}>
                                <p className='text-white helvetica'>{t('education.12')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Help;