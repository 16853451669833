import React, { useEffect } from 'react';

import Main from "../components/Faq/Main";

function Faq () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <Main/>
        </div>
    );
}

export default Faq;