import React, { useEffect } from 'react';

import LazyLoad from "react-lazyload";

import Main from "../components/Home/Main";
import Instruments from "../components/Home/Instruments";
import Advantages from "../components/Home/Advantages";
import TypesAccounts from "../components/Home/TypesAccounts";
import Video from "../components/Home/Video";
import AdvantagesCompany from "../components/Home/AdvantagesCompany";

function Home () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <Main/>
            <Instruments/>
            <LazyLoad><Advantages/></LazyLoad>
            <LazyLoad><TypesAccounts/></LazyLoad>
            <LazyLoad><Video/></LazyLoad>
            <LazyLoad><AdvantagesCompany/></LazyLoad>
        </div>
    );
}

export default Home;