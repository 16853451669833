import React from "react";

import main from '../../img/Education/main.jpg'
import mainmobile from '../../img/Education/mainmobile.jpg'

import { useTranslation } from "react-i18next";

import Fade from 'react-reveal/Fade'

function Main () {

    const { t } = useTranslation();

    return(
        <div className="relative overflow-hidden">
            <div className="max-w-[1280px] mx-auto relative">
                <div className="flex justify-center xl:justify-normal">
                    <div className="absolute">
                        <Fade delay={500}><p className="mt-[98px] xl:mt-[253px] helvetica text-white text-[40px] xl:text-[74px] leading-[1.2] text-center xl:text-start"><span className="text-[#F75524]">{t('education.1')}</span><br/>{t('education.2')}</p></Fade>
                        <Fade delay={700}><p className="max-w-[384px] inter text-[14px] xl:text-[18px] text-white mt-[20px] text-center xl:text-start">{t('education.3')}</p></Fade>
                    </div>
                </div>
            </div>
            <Fade right><div className="bg-[#FF3E0480] rounded-[40px] w-[744px] h-[368px] absolute md:right-[-100px] 2xl:right-[-50px] md:bottom-[-60px] 2xl:bottom-[-40px] hidden md:block ">
                <div className="flex">
                    <Fade bottom delay={1000}><div className="w-[102px] h-[102px] rounded-full bg-[#111113] mt-[62px] ml-[74px] flex cursor-pointer">
                        <svg className="ml-[9px] mt-[4px]" xmlns="http://www.w3.org/2000/svg" width="51" height="57" viewBox="0 0 51 57" fill="none">
                            <path d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z" fill="#16C768"/>
                            <path d="M50 46.2679C51.3333 47.0377 51.3333 48.9623 50 49.7321L38 56.6603C36.6667 57.4301 35 56.4678 35 54.9282V41.0718C35 39.5322 36.6667 38.5699 38 39.3397L50 46.2679Z" fill="white"/>
                        </svg>
                    </div>
                    <p className="inter mt-[102px] text-[16px] xl:text-[18px] ml-[28px] text-white">{t('education.4')}<br/><span className="text-[#FF2E2E]">( 21 сек )</span></p></Fade>
                </div>
            </div></Fade>
            <img src={main} alt="" className="w-full h-[1010px] object-cover hidden md:block"/>
            <img src={mainmobile} alt="" className="w-full block md:hidden"/>
        </div>
    );
}

export default Main;