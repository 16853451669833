import React from "react";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import buttonswiper from '../../img/buttonswiper.svg'

import gobbler from '../../img/Home/gobbler.svg'
import gobblerno from '../../img/Home/gobblerno.svg'

import gobblerwithout from '../../img/Home/globberwithout.svg'

function TypesAccounts () {

    const [swiper, setSwiper] = useState({});

    const { t } = useTranslation();

    return(
        <div className="bg-white relative z-50 overflow-hidden mt-[90px] xl:mt-[180px] pb-[20px] xl:pb-0">
            <div className="rounded-t-[40px] xl:rounded-t-[100px] h-[800px] xl:h-[1163px] bg-cover" style={{backgroundImage: "url('/accounts.jpeg')"}}>
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col relative">
                    <p className="text-white helvetica text-[30px] xl:text-[42px] text-center pt-[20px] xl:pt-[50px]">{t('main.23')}</p>
                    <div className='flex justify-between'>
                        <img
                        onClick={() => swiper.slidePrev()}
                        src={buttonswiper}
                        className='w-[40px] h-[40px] mt-[15px] rotate-180 cursor-pointer absolute xl:left-[-50px] bottom-0 xl:top-[500px]'
                        alt=""
                        />
                        <img
                        onClick={() => swiper.slideNext()}
                        src={buttonswiper}
                        className='w-[40px] h-[40px] mt-[15px] cursor-pointer absolute xl:right-[-50px] bottom-0 right-0 xl:top-[500px]'
                        alt=""
                        />
                    </div>
                </div>
                <div className="max-w-[1280px] mx-auto">
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        // autoplay={{
                        //     delay: 7000,
                        //     disableOnInteraction: false,
                        //  }}
                        slidesPerView={1}
                        className="mt-[20px] xl:mt-[33px]"
                        centeredSlides={true}
                        loop={true}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        breakpoints={{
                            1280: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <div className="pb-[20px] xl:w-[620px] xl:h-[800px] h-[600px] overflow-hidden overflow-y-scroll xl:overflow-y-hidden rounded-[40px] basic mx-[20px] xl:mx-0">
                                <p className="inter text-[30px] xl:text-[42px] font-[700] pt-[30px] mx-[15px] xl:mx-[30px]">Basic</p>
                                <div className="grid md:grid-cols-2">
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobbler} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.24')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobbler} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.25')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobbler} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.26')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobbler} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.27')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobbler} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.28')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobbler} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.29')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">NFP</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.30')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.31')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.32')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.33')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.34')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.35')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.36')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.37')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.38')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.39')}</p>
                                        </div>
                                        <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                            <img src={gobblerno} alt="" />
                                            <p className="inter text-[15px]  ml-[15px] max-w-[401px]">{t('main.40')}</p>
                                        </div>
                                </div>
                                <p className="mx-[15px] xl:mx-[30px] mt-[20px] xl:mt-[40px] text-[15px] ">{t('main.41')}</p>
                                <div className="flex justify-between items-center mx-[15px] xl:mx-[30px] mt-[35px] xl:mt-[50px]">
                                    <div>
                                        <p className="inter font-[800] text-[26px] xl:text-[30px]">250$</p>
                                    </div>
                                    <Link to="https://user.bridgeinvestmarket.com/"><div className="w-[132px] xl:w-[160px] h-[60px] xl:h-[60px] rounded-[16px] flex justify-center items-center bg-[#16C768] cursor-pointer">
                                        <p className="text-white text-[15px]  inter">{t('main.42')}</p>
                                    </div></Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="pb-[20px] xl:w-[620px] xl:h-[800px] h-[600px] overflow-hidden overflow-y-scroll xl:overflow-y-hidden rounded-[40px] gold mx-[20px] xl:mx-0">
                                <p className="inter text-[30px] xl:text-[42px] font-[700] pt-[30px] mx-[15px] xl:mx-[30px] text-[#FFF]">Gold</p>
                                <div className="grid md:grid-cols-2">
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.24')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.25')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.26')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.27')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.28')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.29')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">NFP</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.30')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.31')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.32')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.33')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.34')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.35')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.36')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerno} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.37')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerno} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.38')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerno} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.39')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerno} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.40')}</p>
                                    </div>
                                </div>
                                <p className="mx-[15px] xl:mx-[30px] mt-[20px] xl:mt-[40px] text-[15px]  text-[#FFF]"><span className="font-[700]">{t('main.44')}</span>{t('main.43')}</p>
                                <div className="flex justify-between items-center mx-[15px] xl:mx-[30px] mt-[35px] xl:mt-[50px]">
                                    <div>
                                        <p className="inter font-[800] text-[26px] xl:text-[30px] text-[#FFF]">10 000 $</p>
                                    </div>
                                    <Link to="https://user.bridgeinvestmarket.com/"><div className="w-[132px] xl:w-[160px] h-[60px] xl:h-[60px] rounded-[16px] flex justify-center items-center bg-[#16C768] cursor-pointer">
                                        <p className="text-white text-[15px]  inter">{t('main.42')}</p>
                                    </div></Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:h-[800px] xl:w-[620px] pb-[20px] h-[600px] overflow-hidden overflow-y-scroll xl:overflow-y-hidden rounded-[40px] diamond mx-[20px] xl:mx-0">
                                <p className="inter text-[30px] xl:text-[42px] font-[700] pt-[30px] mx-[15px] xl:mx-[30px] text-[#FFF]">Platinum</p>
                                <div className="grid md:grid-cols-2">
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.24')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.25')} </p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.26')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.27')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.28')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.29')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">NFP</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.30')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.31')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.32')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.33')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.34')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.35')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.36')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.37')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerwithout} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.38')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerno} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.39')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerno} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.40')}</p>
                                    </div>
                                </div>
                                <p className="mx-[15px] xl:mx-[30px] mt-[20px] xl:mt-[40px] text-[15px]  text-[#FFF]"><span className="font-[700]">{t('main.44')}</span>{t('main.45')}</p>
                                <div className="flex justify-between items-center mx-[15px] xl:mx-[30px] mt-[35px] xl:mt-[50px]">
                                    <div>
                                        <p className="inter font-[800] text-[26px] xl:text-[30px] text-[#FFF]">50 000 $</p>
                                    </div>
                                    <Link to="https://user.bridgeinvestmarket.com/"><div className="w-[132px] xl:w-[160px] h-[60px] xl:h-[60px] rounded-[16px] flex justify-center items-center bg-[#16C768] cursor-pointer">
                                        <p className="text-white text-[15px]  inter">{t('main.42')}</p>
                                    </div></Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="pb-[20px] xl:w-[620px] xl:h-[800px] h-[600px] overflow-hidden overflow-y-scroll xl:overflow-y-hidden platinum rounded-[40px]  mx-[20px] xl:mx-0">
                                <p className="inter text-[30px] xl:text-[42px] font-[700] pt-[30px] mx-[15px] xl:mx-[30px] text-[#FFF]">Diamond</p>
                                <div className="grid md:grid-cols-2">
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.24')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.25')} </p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.26')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.27')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.28')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.29')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">NFP</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.30')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.31')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.32')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.33')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.34')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.35')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.36')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.37')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.38')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerno} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.39')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobblerno} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.40')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.48')}</p>
                                    </div>
                                </div> 
                                <p className="mx-[15px] xl:mx-[30px] mt-[20px] xl:mt-[40px] text-[15px]  text-[#FFF]"><span className="font-[700]">{t('main.44')}</span>{t('main.47')}</p>
                                <div className="flex justify-between items-center mx-[15px] xl:mx-[30px] mt-[35px] xl:mt-[15px]">
                                    <div>
                                        <p className="inter font-[800] text-[26px] xl:text-[30px] text-[#FFF]">25 000 $ - 125 000 $</p>
                                    </div>
                                    <Link to="https://user.bridgeinvestmarket.com/"><div className="w-[132px] xl:w-[160px] h-[60px] xl:h-[60px] rounded-[16px] flex justify-center items-center bg-[#16C768] cursor-pointer">
                                        <p className="text-white text-[15px]  inter">{t('main.42')}</p>
                                    </div></Link>
                                </div> 
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="xl:h-[800px] xl:w-[620px] pb-[20px] h-[600px] overflow-hidden overflow-y-scroll xl:overflow-y-hidden rounded-[40px] vip mx-[20px] xl:mx-0">
                                <p className="inter text-[30px] xl:text-[42px] font-[700] pt-[30px] mx-[15px] xl:mx-[30px] text-[#FFF]">Vip</p>
                                <div className="grid md:grid-cols-2">
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.24')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.25')} </p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.26')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.27')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.28')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.29')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">NFP</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.30')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.31')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.32')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.33')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.34')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.35')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.36')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.37')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.38')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.39')}</p>
                                    </div>
                                    <div className="flex mx-[15px] xl:mx-[30px] mt-[15px] items-start">
                                        <img src={gobbler} alt="" />
                                        <p className="inter text-[15px]  ml-[15px] max-w-[291px] text-[#FFF]">{t('main.40')}</p>
                                    </div>
                                </div>                        
                                <p className="mx-[15px] xl:mx-[30px] mt-[20px] xl:mt-[40px] text-[15px]  text-[#FFF]"><span className="font-[700]">{t('main.44')}</span>{t('main.46')}</p>
                                <div className="flex justify-between items-center mx-[15px] xl:mx-[30px] mt-[35px] xl:mt-[50px]">
                                    <div>
                                        <p className="inter font-[800] text-[26px] xl:text-[30px] text-[#FFF]">250 000 $</p>
                                    </div>
                                    <Link to="https://user.bridgeinvestmarket.com/"><div className="w-[132px] xl:w-[160px] h-[60px] xl:h-[60px] rounded-[16px] flex justify-center items-center bg-[#16C768] cursor-pointer">
                                        <p className="text-white text-[15px]  inter">{t('main.42')}</p>
                                    </div></Link>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            
        </div>
    );
}

export default TypesAccounts;