import React from "react";

import { useTranslation, Trans } from "react-i18next";

import advantages from '../../img/Home/advantages.png'
import advantages2 from '../../img/Home/advantages2.png'

import a02 from '../../img/Home/02.png'
import a03 from '../../img/Home/03.png' 
import a04 from '../../img/Home/04.png'

function Advantages () {

    const { t } = useTranslation();

    return(
        <div className="bg-white relative z-50 overflow-hidden mt-[90px] xl:mt-[180px]">
            <div className="max-w-[1280px] mx-auto">
                <div className="flex flex-col xl:flex-row justify-between items-center">
                    <div className="mx-[20px] xl:mx-0">
                        <p className="xl:max-w-[476px] text-[30px] xl:text-[42px] helvetica text-center xl:text-left max-w-[327px] md:max-w-max">{t('main.16')}</p>
                    </div>
                    <div className="mx-[20px] xl:mx-0">
                        <div className="flex flex-col xl:flex-row justify-center items-center">
                            <div className="h-[84px] w-[4px] rotate-90 xl:rotate-0 bg-[#F75524]"></div>
                            <p className="max-w-[558px] inter text-[18px] leading-[1.6] xl:ml-[26px] text-center xl:text-left">{t('main.17')}</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between items-center mt-[40px]">
                    <div className="xl:w-[688px] xl:h-[754px] rounded-[40px] relative mx-[20px] xl:mx-0">
                        <img src={advantages} alt="" className="object-cover h-full rounded-[40px]"/>
                        <div className="absolute top-0 left-0 z-50">
                            <p className="text-[#F75524] text-[18px] inter mt-[30px] ml-[30px]">✔</p>
                            <p className="mt-[20px] xl:mt-[45px] inter text-[18px] ml-[30px] text-white max-w-[161px] xl:max-w-[208px]">{t('main.18')}</p>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-[20px] mx-[20px] xl:mx-0 mt-[20px] xl:mt-0 relative">
                            <div className="xl:w-[282px] h-[192px] border border-[#6E7882] rounded-[40px]">
                                <img src={a02} alt="" className="object-cover h-full w-full rounded-[40px]"/>
                                <div className="absolute top-0 left-0 z-50">
                                    <p className="text-[#F75524] text-[18px] inter mt-[30px] ml-[30px]">✔</p>
                                    <p className="mt-[45px] inter text-[18px] ml-[30px] text-white"><Trans>{t('main.19')}</Trans></p>
                                </div>
                            </div>
                            <div className="xl:w-[282px] h-[192px] border border-[#6E7882] rounded-[40px] relative">
                                <img src={a03} alt="" className="object-cover h-full w-full rounded-[40px]"/>
                                <div className="absolute top-0 left-0 z-50">
                                    <p className="text-[#F75524] text-[18px] inter mt-[30px] ml-[30px]">✔</p>
                                    <p className="mt-[45px] inter text-[18px] ml-[30px] max-w-[208px] text-white"><Trans>{t('main.20')}</Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="xl:w-[584px] h-[192px] border border-[#6E7882] rounded-[40px] mt-[20px] mx-[20px] xl:mx-0 relative">
                                <img src={a04} alt="" className="object-cover h-full w-full rounded-[40px]"/>
                                <div className="absolute top-0 left-0 z-50">
                                    <p className="text-[#F75524] text-[18px] inter mt-[30px] ml-[30px]">✔</p>
                                    <p className="mt-[45px] inter text-[18px] ml-[30px] text-white"><Trans>{t('main.21')}</Trans></p>
                                </div>
                            </div>
                        <div className="xl:w-[588px] h-[330px] rounded-[40px] mt-[20px] mx-[20px] xl:mx-0 relative">
                            <img src={advantages2} alt="" className="object-cover h-full rounded-[40px]"/>
                            <div className="absolute top-0 left-0 z-50">
                                <p className="text-[#F75524] text-[18px] inter mt-[30px] ml-[30px]">✔</p>
                                <p className="mt-[20px] xl:mt-[45px] inter text-[18px] ml-[30px] text-white max-w-[127px] xl:max-w-[208px]">{t('main.22')}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Advantages;