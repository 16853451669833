import React, { useEffect } from 'react';

import main from '../img/2.png'

import Fade from 'react-reveal/Fade'

import { useTranslation, Trans } from "react-i18next";

function Calendar () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const { t } = useTranslation();

    return(
        <>
        <div className="relative overflow-hidden">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative">
                <div className="flex justify-center xl:justify-normal">
                    <div className="absolute">
                        <Fade delay={500}><p className="helvetica mt-[98px] xl:mt-[203px] text-white text-[40px] xl:text-[74px] leading-[1.2] text-center xl:text-start"><Trans>{t('calendar.1')}</Trans></p></Fade>
                        <Fade delay={700}><p className="inter text-[14px] xl:text-[18px] text-white mt-[20px] text-center xl:text-start max-w-[509px] mx-auto xl:mx-0">{t('calendar.2')}</p></Fade>
                    </div>
                </div>
            </div>
            <img src={main} alt="" className="w-full object-cover h-[600px] xl:h-[750px]"/>
            <div className='absolute h-[100px] bg-white w-full bottom-0 rounded-t-[40px] xl:rounded-t-[100px]'></div>
        </div>
        <div className='pb-[20px] max-w-[1280px] mx-auto'>
            {/* <div className='max-w-[650px] mx-[20px] xl:mx-auto flex flex-col'>
                <iframe className='mx-auto' title="test" src="https://sslecal2.investing.com?ecoDayBackground=%23f75625&columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=datepicker,timezone&countries=25,32,6,37,72,22,17,39,14,10,35,43,56,36,110,11,26,12,4,5&calType=day&timeZone=68&lang=1" width="100%" height="467" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0"></iframe>
            </div> */}
            <iframe className='w-full h-[600px]' src="/Calendar.html" title='0' frameborder="0"></iframe>
        </div>
        </>

    );
}

export default Calendar;