import React, { useEffect } from 'react';

import Main from "../components/Accounts/Main";
import Help from "../components/Education/Help";

function Accounts () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <Main/>
            <Help/>
        </div>
    );
}

export default Accounts;