import React, { useEffect } from 'react';

import LazyLoad from "react-lazyload";

import Main from "../components/Contacts/Main";
import Phone from "../components/Contacts/Phone";
import Help from "../components/Education/Help";


function Contacts () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <Main/>
            <Phone/>
            <LazyLoad><Help/></LazyLoad>
        </div>
    );
}

export default Contacts;