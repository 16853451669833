import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";

function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <div>
            <div className="relative z-[900] w-[30px] h-[25px] cursor-pointer flex flex-col justify-between" onClick={toggleMenu}>
                <div className={`w-full h-[3px] transition duration-300 transform ${isOpen ? 'rotate-45 translate-y-[9px] bg-[#F75524] w-[35px]' : 'bg-[#F75524]'}`}></div>
                <div className={`w-full h-[3px] bg-[#F75524] transition duration-300 transform ${isOpen ? 'hidden' : 'block'}`}></div>
                <div className={`w-full h-[3px]  transition duration-300 transform ${isOpen ? '-rotate-45 -translate-y-[14px] bg-[#F75524] w-[35px]' : 'bg-[#F75524]'}`}></div>
            </div>
            <div className={`fixed top-0 right-0 bottom-0 w-full md:w-1/2 bg-black z-50 transform transition-all duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="flex flex-col items-center mt-[150px] gap-[30px]">
                    <Link to="/"><p className={`text-white text-[25px] inter ${location.pathname === '/' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.1')}</p></Link>
                    <Link to="/education"><p className={`text-white text-[25px] inter ${location.pathname === '/education' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.2')}</p></Link>
                    <Link to="/accounts"><p className={`text-white text-[25px] inter ${location.pathname === '/accounts' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.8')}</p></Link>
                    <Link to="/contacts"><p className={`text-white text-[25px] inter ${location.pathname === '/contacts' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.3')}</p></Link>
                    <Link to="/news"><p className={`text-white text-[25px] inter ${location.pathname === '/news' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.4')}</p></Link>
                    <Link to="/calendar"><p className={`text-white text-[25px] inter ${location.pathname === '/calendar' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.5')}</p></Link>
                    <Link to="/faq"><p className={`text-white text-[25px] inter ${location.pathname === '/faq' ? 'font-medium' : 'font-light'}`} onClick={toggleMenu}>{t('header.9')}</p></Link>
                    <div className="flex gap-[40px]">
                        <Link to="https://user.bridgeinvestmarket.com/"><div className="border border-[#F75524] rounded-[10px] py-[2px] px-[20px] mt-[-2px]">
                            <p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('header.7')}</p>
                        </div></Link>
                        <Link to="https://user.bridgeinvestmarket.com/"><p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('header.6')}</p></Link>
                    </div>
    
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;