import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import { useTranslation } from "react-i18next";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import video1 from '../../img/Home/video1.png'
import buttonswiper from '../../img/buttonswiper.svg'

function SeeVideo () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
          document.body.classList.add('overflow-hidden');
        } else {
          document.body.classList.remove('overflow-hidden');
        }
      }, [isOpen]);

    return(
        <>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-[999]">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center relative xl:w-[500px] flex flex-col items-center">
                        <button 
                            onClick={closeModal} 
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl">
                            &times;
                        </button>
                        <h1 className="text-2xl font-bold mb-4">{t('education.13')}</h1>
                        <p className="mb-4">{t('education.14')}</p>
                        <div className='flex gap-[50px]'>
                            <Link to="https://user.bridgeinvestmarket.com/"><div className="border border-[#F75524] rounded-[10px] py-[2px] px-[20px] mt-[-2px]">
                                <p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('education.15')}</p>
                            </div></Link>
                            <Link to="https://user.bridgeinvestmarket.com/"><p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('education.16')}</p></Link>
                        </div>
                    </div>
                </div>
            )}
            <div className="bg-white mt-[80px] xl:mt-[160px] relative z-50 overflow-hidden">
                <div className="max-w-[1280px] mx-auto flex flex-col">
                    <div className="flex flex-col xl:flex-row justify-between items-center">
                        <div className="mx-[20px] xl:mx-0">
                            <p className="xl:max-w-[476px] text-[30px] xl:text-[42px] helvetica text-center xl:text-left max-w-[327px] md:max-w-max">{t('education.17')}</p>
                        </div>
                        <div className="mx-[20px] xl:mx-0">
                            <div className="flex flex-col xl:flex-row justify-center items-center">
                                <div className="h-[84px] w-[4px] rotate-90 xl:rotate-0 bg-[#F75524]"></div>
                                <div className="flex flex-col xl:flex-row items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M48.7501 33.4401V46.7114C48.7501 49.895 45.9888 52.5 42.615 52.5H17.3851C14.0088 52.5 11.25 49.895 11.25 46.7114V33.4401C11.25 30.2562 14.0088 27.6514 17.3851 27.6514H42.615C45.9888 27.6514 48.7501 30.2562 48.7501 33.4401Z" fill="#D42927"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8574 32.0898V34.126L16.46 34.133V48.1516H19.0673V34.1723H22.1948V32.1016L13.8574 32.0898Z" fill="#FFFFFE"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6838 36.2546L21.6914 43.6521C21.6939 44.787 21.6645 45.6321 21.7013 46.0176C21.7644 46.6965 21.7401 47.487 22.3701 47.9389C23.5443 48.7839 25.7931 47.8121 26.3544 46.6033L26.3494 48.1464L28.4444 48.1489L28.442 36.2545H25.8344V43.7201C25.8344 44.7996 25.9002 45.3389 25.832 45.5295C25.6195 46.1076 24.6676 46.7232 24.2938 45.5928C24.2327 45.3952 24.2863 44.7971 24.2863 43.7715L24.277 36.2545L21.6838 36.2546ZM30.0071 32.1041L29.9945 48.0491L32.1357 48.0172L32.3308 47.0235C35.0677 49.5359 36.789 47.8147 36.7839 44.8047L36.7796 38.5885C36.7771 36.2228 35.0046 34.8048 32.5996 36.7185L32.612 32.0991L30.0071 32.1041L30.0071 32.1041ZM32.5677 38.2785C32.5677 37.7634 33.0807 37.3409 33.7058 37.3409C34.3358 37.3409 34.8483 37.7634 34.8483 38.2785V45.2535C34.8483 45.7685 34.3358 46.1886 33.7058 46.1886C33.0807 46.1886 32.5677 45.7685 32.5677 45.2535V38.2785ZM39.782 36.184C39.2865 36.5403 38.9082 37.0165 38.6882 37.6583C38.4664 38.2977 38.3564 39.1722 38.3564 40.2853V43.989C38.3564 50.1483 45.837 49.2772 44.9459 43.979L42.9901 43.9915C42.987 44.0794 42.9854 44.1673 42.9852 44.2552V45.3466C42.9852 45.9296 42.5021 46.4034 41.9158 46.4034H41.5326C40.9471 46.4034 40.4632 45.9296 40.4632 45.3466V42.4753H44.9407V40.791C44.9407 39.5602 44.9089 38.3296 44.8089 37.624C44.487 35.3977 41.362 35.0434 39.782 36.184ZM41.8333 37.8222C42.4726 37.8222 42.9978 38.3447 42.9978 38.9816L42.9708 40.4147H40.5003L40.5121 38.9816C40.5121 38.3447 41.0346 37.8222 41.6745 37.8222L41.8333 37.8222Z" fill="#FFFFFE"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3371 15.6418L20.3423 25.1175H23.0841L23.0866 15.6376L26.326 7.51688H23.3278L21.604 13.5475L19.8584 7.5H16.8896L20.3371 15.6418ZM26.404 14.1039C26.2091 14.7732 26.1084 15.6982 26.1084 16.885V20.1589C26.1084 21.2401 26.1596 22.0531 26.2697 22.5951C26.3797 23.1351 26.5602 23.5989 26.8166 23.9794C27.0703 24.3607 27.4273 24.6488 27.8884 24.8362C28.3471 25.0295 28.9066 25.1195 29.5685 25.1195C30.1615 25.1195 30.6866 25.0125 31.1452 24.7875C31.5997 24.5682 31.9728 24.2237 32.2634 23.7601C32.5591 23.2938 32.749 22.7956 32.8372 22.2581C32.9253 21.7238 32.9715 20.8789 32.9715 19.7338V16.6063C32.9715 15.7007 32.9228 15.0294 32.8271 14.5826C32.7296 14.1432 32.5516 13.7132 32.2878 13.2957C32.029 12.8783 31.6578 12.5413 31.1722 12.2826C30.691 12.0238 30.1152 11.892 29.4434 11.892C28.6428 11.892 27.9859 12.095 27.4735 12.5C26.9585 12.9032 26.6015 13.4376 26.404 14.1039ZM28.384 21.7944V15.2151C28.384 14.5169 28.9066 13.9449 29.5416 13.9449C30.176 13.9449 30.6935 14.5169 30.6935 15.2151V21.7944C30.6935 22.4949 30.176 23.0663 29.5416 23.0663C28.9066 23.0663 28.384 22.4949 28.384 21.7944ZM34.6172 12.3706V20.708C34.6172 21.77 34.6366 22.4781 34.6684 22.8369C34.7003 23.1931 34.7829 23.5351 34.9172 23.8674C35.0516 24.1918 35.2591 24.4581 35.5447 24.6581C35.8228 24.8562 36.1823 24.9538 36.6116 24.9538C36.9879 24.9538 37.3197 24.8537 37.6105 24.6438C37.8985 24.4338 38.1423 24.1238 38.3404 23.7087L38.2916 24.7313H41.1991V12.3706H38.9116V21.99C38.9116 22.5125 38.4816 22.937 37.9566 22.937C37.4347 22.937 37.0047 22.5125 37.0047 21.99V12.3706H34.6172Z" fill="black"/>
                                    </svg>
                                    <p className="max-w-[558px] inter text-[18px] leading-[1.6] xl:ml-[8px] mt-[10px] xl:mt-0 text-center xl:text-left">{t('education.18')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[20px] xl:mt-[40px]">
                        <Swiper
                            modules={[Navigation]}
                            slidesPerView={1}
                            breakpoints={{
                                1200: {
                                slidesPerView: 3.2
                                }
                            }}
                            onInit={(e) => {
                                setSwiper(e);
                            }}
                        >
                            <SwiperSlide>
                                <div>
                                    <div className="xl:w-[384px] h-[220px] mx-[20px] xl:mx-0 rounded-[16px] relative flex items-center justify-center shadow-md">
                                        <img src={video1} alt="" className="z-0 object-cover h-full w-full rounded-[16px]"/>
                                        <svg className="absolute z-50 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <path d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27Z" fill="#F75524"/>
                                            <path d="M36 25.2679C37.3333 26.0377 37.3333 27.9622 36 28.732L24 35.6603C22.6667 36.4301 21 35.4678 21 33.9282V20.0718C21 18.5322 22.6667 17.5699 24 18.3397L36 25.2679Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p className="text-[14px] xl:text-[18px] inter mt-[10px] xl:mt-[20px] max-w-[384px] mx-[30px] xl:mx-0">{t('education.19')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <div className="xl:w-[384px] h-[220px] mx-[20px] xl:mx-0 rounded-[16px] relative flex items-center justify-center shadow-md">
                                        <img src={video1} alt="" className="z-0 object-cover h-full w-full rounded-[16px]"/>
                                        <svg className="absolute z-50 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <path d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27Z" fill="#F75524"/>
                                            <path d="M36 25.2679C37.3333 26.0377 37.3333 27.9622 36 28.732L24 35.6603C22.6667 36.4301 21 35.4678 21 33.9282V20.0718C21 18.5322 22.6667 17.5699 24 18.3397L36 25.2679Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p className="text-[14px] xl:text-[18px] inter mt-[10px] xl:mt-[20px] max-w-[384px] mx-[30px] xl:mx-0">{t('education.20')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <div className="xl:w-[384px] h-[220px] mx-[20px] xl:mx-0 rounded-[16px] relative flex items-center justify-center shadow-md">
                                        <img src={video1} alt="" className="z-0 object-cover h-full w-full rounded-[16px]"/>
                                        <svg className="absolute z-50 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <path d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27Z" fill="#F75524"/>
                                            <path d="M36 25.2679C37.3333 26.0377 37.3333 27.9622 36 28.732L24 35.6603C22.6667 36.4301 21 35.4678 21 33.9282V20.0718C21 18.5322 22.6667 17.5699 24 18.3397L36 25.2679Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p className="text-[14px] xl:text-[18px] inter mt-[10px] xl:mt-[20px] max-w-[384px] mx-[30px] xl:mx-0">{t('education.21')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <div className="xl:w-[384px] h-[220px] mx-[20px] xl:mx-0 rounded-[16px] relative flex items-center justify-center shadow-md">
                                        <img src={video1} alt="" className="z-0 object-cover h-full w-full rounded-[16px] blur-sm"/>
                                        <svg onClick={openModal} className="absolute z-50 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <circle cx="27" cy="27" r="27" fill="#F75524"/>
                                            <path d="M27.5 18C24.9153 18 22.8125 20.0187 22.8125 22.5V25.2H21.875C21.3777 25.2 20.9008 25.3896 20.5492 25.7272C20.1975 26.0648 20 26.5226 20 27V34.2C20 34.6774 20.1975 35.1352 20.5492 35.4728C20.9008 35.8104 21.3777 36 21.875 36H33.125C33.6223 36 34.0992 35.8104 34.4508 35.4728C34.8025 35.1352 35 34.6774 35 34.2V27C35 26.5226 34.8025 26.0648 34.4508 25.7272C34.0992 25.3896 33.6223 25.2 33.125 25.2H32.1875V22.5C32.1875 20.0187 30.0847 18 27.5 18ZM24.6875 22.5C24.6875 21.0114 25.9494 19.8 27.5 19.8C29.0506 19.8 30.3125 21.0114 30.3125 22.5V25.2H24.6875V22.5ZM28.4375 32.1507V34.2H26.5625V32.1507C26.2347 31.9705 25.9716 31.6992 25.8077 31.3725C25.6438 31.0458 25.5868 30.6792 25.6444 30.3208C25.702 29.9624 25.8713 29.6292 26.1301 29.3651C26.3889 29.101 26.725 28.9184 27.0941 28.8414C27.3682 28.7832 27.6525 28.7848 27.9259 28.8462C28.1993 28.9075 28.4548 29.0269 28.6737 29.1957C28.8926 29.3645 29.0693 29.5783 29.1907 29.8213C29.312 30.0644 29.375 30.3305 29.375 30.6C29.3745 30.9148 29.2875 31.2238 29.1229 31.4961C28.9583 31.7683 28.7219 31.9941 28.4375 32.1507Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p className="text-[14px] xl:text-[18px] inter mt-[10px] xl:mt-[20px] max-w-[384px] mx-[30px] xl:mx-0">{t('education.22')}</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className='flex mx-auto xl:mx-0 xl:ml-auto mt-[20px]'>
                        <img
                        onClick={() => swiper.slidePrev()}
                        src={buttonswiper}
                        className='w-[40px] h-[40px] rotate-180 cursor-pointer'
                        alt=""
                        />
                        <img
                        onClick={() => swiper.slideNext()}
                        src={buttonswiper}
                        className='w-[40px] h-[40px] ml-[20px] cursor-pointer'
                        alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SeeVideo;