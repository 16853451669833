import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

const one = '/WorldIndices.html'
const two = '/Crypto.html'
const three = '/Equities.html'
const four = '/World.html'
const five = '/European.html'
const six = '/Commodities.html'
const seven = '/ForexE.html'
const eight = '/MajorsMetal.html'
const nine = '/ForexMinors.html'
const ten = '/ForexMajors.html'

const data = [
    {
        name: 'World Indicies',
        new: false,
        iframe: one
    },
    {
        name: 'Crypto Currency',
        new: false,
        iframe: two
    },
    {
        name: 'US Equities',
        new: false,
        iframe: three
    },
    {
        name: 'World Equities',
        new: true,
        iframe: four
    },
    {
        name: 'European Equities',
        new: false,
        iframe: five
    },
    {
        name: 'Commodities',
        new: false,
        iframe: six
    },
    {
        name: 'Forex Exotics',
        new: false,
        iframe: seven
    },
    {
        name: 'Majors Metals',
        new: false,
        iframe: eight
    },
    {
        name: 'Forex Minors',
        new: false,
        iframe: nine
    },
    {
        name: 'Forex Majors',
        new: false,
        iframe: ten
    },
]
 
function Instruments () {

    const [selectedPage, setSelectedPage] = useState(data[0]);

    const { t } = useTranslation();

    return(
        <div className="bg-white rounded-t-[40px] xl:rounded-t-[100px] mt-[-100px] relative z-50 overflow-hidden">
            <div className="flex flex-col items-center pt-[50px] xl:pt-[100px]">
                <p className="helvetica text-[30px] xl:text-[42px] max-w-[327px] text-center xl:max-w-full xl:text-start">{t('main.7')}</p>
                <div className="flex gap-[30px] mt-[43px] relative max-w-[350px] md:max-w-max overflow-y-auto">
                
                    <div className="flex flex-wrap gap-[30px] justify-center">
                        {data.map((item, index) => (
                            <div key={index} className={`cursor-pointer rounded-[20px] ${selectedPage === item ? 'border-[#F75524] border' : 'bg-[#EBEBEB]'} flex items-center justify-center py-[8px] px-[50px]`} onClick={() => setSelectedPage(item)}>
                                <p className={`inter text-[18px] ${selectedPage === item ? 'text-[#F75524]' : ''}`}>{item.name}</p>
                                {item.new && (
                                    <p className="text-[10px] inter font-[700] text-[#16C768]">NEW</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>  
                <iframe src={selectedPage.iframe} frameborder="0" title={selectedPage.name} className='w-[100%] mt-[30px]'></iframe>
            </div>
        </div>
    );
}

export default Instruments;