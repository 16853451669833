import React from "react";

import logo from '../img/logo.svg'

import { useTranslation, Trans } from "react-i18next";

function Footer () {

    const { t } = useTranslation();
    
    return(
        <div className="relative">  
            <div className="bg-black">
                <div className="max-w-[1280px] mx-auto pt-[40px]">
                    <img src={logo} alt="" className="xl:w-[133px] xl:h-[70px] w-[84px] h-[49px] xl:hidden block mx-auto"/>
                    <div className="flex gap-[85px] flex-wrap mt-[40px] xl:mt-0 justify-center xl:justify-start">
                        <img src={logo} alt="" className="xl:w-[133px] xl:h-[70px] w-[84px] h-[49px] hidden xl:block"/>
                        <div className="flex">
                            <div className="w-[1px] h-[150px] bg-[#26262F]"></div>
                            <div className="ml-[25px]">
                                <div className="flex gap-[10px]">
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.1')}</p>
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">+97145429388</p>
                                </div>
                                <div className="flex gap-[10px]">
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.central_office')}</p>
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">Unit 03, Level 2, Building 1 Currency House, DIFC, PO Box 506530, Dubai, UAE</p>
                                </div>
                                <div className="flex gap-[10px]">
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.2')}</p>
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">20 Gresham Street, 4th Floor, London EC2V 7JE, UK.<br/>FCA No. 217689</p>
                                </div>
                                <div className="flex gap-[10px]">
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.3')}</p>
                                    <p className="text-[#6E7882] inter text-[14px] mt-[20px]">info@bridgeinvest.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="ml-[25px] pb-[30px]">
                            <p className="text-[#6E7882] inter text-[14px] mt-[20px]">{t('footer.7')}</p>
                            <p className="text-[#6E7882] inter text-[14px] mt-[20px]"><Trans>{t('footer.8')}</Trans></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#F75524] h-[21px]">
                <div className="max-w-[1280px] mx-auto flex justify-center items-center">
                    <p className="text-black inter text-[14px]">BIM 2002-2024</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;