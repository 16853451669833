import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import video1 from '../../img/Home/video1.png'

import buttonswiper from '../../img/buttonswiper.svg'

function Video () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
          document.body.classList.add('overflow-hidden');
        } else {
          document.body.classList.remove('overflow-hidden');
        }
      }, [isOpen]);

    return(
        <>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-[999]">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center relative xl:w-[500px] flex flex-col items-center">
                        <button 
                            onClick={closeModal} 
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl">
                            &times;
                        </button>
                        <h1 className="text-2xl font-bold mb-4">{t('main.49')}</h1>
                        <p className="mb-4"></p>
                        <div className='flex gap-[50px]'>
                            <Link to="https://user.bridgeinvestmarket.com/"><div className="border border-[#F75524] rounded-[10px] py-[2px] px-[20px] mt-[-2px]">
                                <p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('main.50')}</p>
                            </div></Link>
                            <Link to="https://user.bridgeinvestmarket.com/"><p className="text-[#F75524] text-[16px] xl:text-[18px] inter">{t('main.51')}</p></Link>
                        </div>
                    </div>
                </div>
            )}
            <div className="bg-white rounded-t-[40px] xl:rounded-t-[100px] mt-[-100px] relative z-50 overflow-hidden">
                <div className="max-w-[1280px] mx-auto pt-[50px] xl:pt-[250px] flex flex-col">    
                    <div className="flex flex-col xl:flex-row justify-between items-center">
                        <div className="mx-[20px] xl:mx-0">
                            <p className="xl:max-w-[476px] text-[30px] xl:text-[42px] helvetica text-center xl:text-left max-w-[327px] md:max-w-max">{t('main.52')}</p>
                        </div>
                        <div className="mx-[20px] xl:mx-0">
                            <div className="flex flex-col xl:flex-row justify-center items-center">
                                <div className="h-[84px] w-[4px] rotate-90 xl:rotate-0 bg-[#F75524]"></div>
                                <p className="max-w-[558px] inter text-[18px] leading-[1.6] xl:ml-[26px] text-center xl:text-left">{t('main.53')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[20px] xl:mt-[40px]">
                        <Swiper
                            modules={[Navigation]}
                            slidesPerView={1}
                            breakpoints={{
                                1200: {
                                slidesPerView: 3.2
                                }
                            }}
                            onInit={(e) => {
                                setSwiper(e);
                            }}
                        >
                            <SwiperSlide>
                                <div>
                                    <div className="xl:w-[384px] h-[220px] mx-[20px] xl:mx-0 rounded-[16px] relative flex items-center justify-center shadow-md">
                                        <img src={video1} alt="" className="z-0 object-cover h-full w-full rounded-[16px]"/>
                                        <svg className="absolute z-50 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <path d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27Z" fill="#F75524"/>
                                            <path d="M36 25.2679C37.3333 26.0377 37.3333 27.9622 36 28.732L24 35.6603C22.6667 36.4301 21 35.4678 21 33.9282V20.0718C21 18.5322 22.6667 17.5699 24 18.3397L36 25.2679Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p className="text-[14px] xl:text-[18px] inter mt-[10px] xl:mt-[20px] max-w-[384px] mx-[30px] xl:mx-0">{t('main.55')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <div className="xl:w-[384px] h-[220px] mx-[20px] xl:mx-0 rounded-[16px] relative flex items-center justify-center shadow-md">
                                        <img src={video1} alt="" className="z-0 object-cover h-full w-full rounded-[16px]"/>
                                        <svg className="absolute z-50 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <path d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27Z" fill="#F75524"/>
                                            <path d="M36 25.2679C37.3333 26.0377 37.3333 27.9622 36 28.732L24 35.6603C22.6667 36.4301 21 35.4678 21 33.9282V20.0718C21 18.5322 22.6667 17.5699 24 18.3397L36 25.2679Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p className="text-[14px] xl:text-[18px] inter mt-[10px] xl:mt-[20px] max-w-[384px] mx-[30px] xl:mx-0">{t('main.56')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <div className="xl:w-[384px] h-[220px] mx-[20px] xl:mx-0 rounded-[16px] relative flex items-center justify-center shadow-md">
                                        <img src={video1} alt="" className="z-0 object-cover h-full w-full rounded-[16px]"/>
                                        <svg className="absolute z-50 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <path d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27Z" fill="#F75524"/>
                                            <path d="M36 25.2679C37.3333 26.0377 37.3333 27.9622 36 28.732L24 35.6603C22.6667 36.4301 21 35.4678 21 33.9282V20.0718C21 18.5322 22.6667 17.5699 24 18.3397L36 25.2679Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p className="text-[14px] xl:text-[18px] inter mt-[10px] xl:mt-[20px] max-w-[384px] mx-[30px] xl:mx-0">{t('main.57')}</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <div className="xl:w-[384px] h-[220px] mx-[20px] xl:mx-0 rounded-[16px] relative flex items-center justify-center shadow-md">
                                        <img src={video1} alt="" className="z-0 object-cover h-full w-full rounded-[16px] blur-sm"/>
                                        <svg onClick={openModal} className="absolute z-50 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <circle cx="27" cy="27" r="27" fill="#F75524"/>
                                            <path d="M27.5 18C24.9153 18 22.8125 20.0187 22.8125 22.5V25.2H21.875C21.3777 25.2 20.9008 25.3896 20.5492 25.7272C20.1975 26.0648 20 26.5226 20 27V34.2C20 34.6774 20.1975 35.1352 20.5492 35.4728C20.9008 35.8104 21.3777 36 21.875 36H33.125C33.6223 36 34.0992 35.8104 34.4508 35.4728C34.8025 35.1352 35 34.6774 35 34.2V27C35 26.5226 34.8025 26.0648 34.4508 25.7272C34.0992 25.3896 33.6223 25.2 33.125 25.2H32.1875V22.5C32.1875 20.0187 30.0847 18 27.5 18ZM24.6875 22.5C24.6875 21.0114 25.9494 19.8 27.5 19.8C29.0506 19.8 30.3125 21.0114 30.3125 22.5V25.2H24.6875V22.5ZM28.4375 32.1507V34.2H26.5625V32.1507C26.2347 31.9705 25.9716 31.6992 25.8077 31.3725C25.6438 31.0458 25.5868 30.6792 25.6444 30.3208C25.702 29.9624 25.8713 29.6292 26.1301 29.3651C26.3889 29.101 26.725 28.9184 27.0941 28.8414C27.3682 28.7832 27.6525 28.7848 27.9259 28.8462C28.1993 28.9075 28.4548 29.0269 28.6737 29.1957C28.8926 29.3645 29.0693 29.5783 29.1907 29.8213C29.312 30.0644 29.375 30.3305 29.375 30.6C29.3745 30.9148 29.2875 31.2238 29.1229 31.4961C28.9583 31.7683 28.7219 31.9941 28.4375 32.1507Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p className="text-[14px] xl:text-[18px] inter mt-[10px] xl:mt-[20px] max-w-[384px] mx-[30px] xl:mx-0">{t('main.58')}</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className='flex mx-auto xl:mx-0 xl:ml-auto mt-[20px]'>
                        <img
                        onClick={() => swiper.slidePrev()}
                        src={buttonswiper}
                        className='w-[40px] h-[40px] rotate-180 cursor-pointer'
                        alt=""
                        />
                        <img
                        onClick={() => swiper.slideNext()}
                        src={buttonswiper}
                        className='w-[40px] h-[40px] ml-[20px] cursor-pointer'
                        alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Video;